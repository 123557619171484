  .cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f5f5f5;
    margin: 8px;
    padding: 12px 0;
  }

  .cover {
    width: 200px;
    height: 120px;
    border-radius: 8px;
  }