.user-learning-page-container{
    max-width: 1200px;
    margin: auto;
    padding-top: 20px;
}

.user-learning-page-background{
    background-color: #ffffff;
}

.back {
    margin: 12px;
}

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .searchForm {
    width: '100%';
  }

  .row {
      width: 1200px;
  }

  @media screen and (min-width: 1200px) {
      .row {
        width: 1200px;
      }
  }

  @media screen and (max-width: 1199px) {
    .row {
      width: 700px;
    }
}

@media screen and (max-width: 767px) {
    .row {
      width: 375px;
    }
}

.pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
}

.tab_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.split {
    height: 8px;
    width: 48px;
    margin-top: 2px;
    background-color: #FFC001;
}

.split_line {
    width: 2px;
    height: 30px;
    background-color: '#f5f5f5';
    margin-top: 10px;
}

.serial_tag {
    background-color: '#FFC600';
    margin-left: 8px;
    height: 30px;
    border-radius: 15px;
}