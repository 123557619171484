.user-study-page-container{
    max-width: 1200px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
}

.user-study-page-background{
    background-color: #e6e8eb;
}
