  .file-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f5f5f5;
    margin: 8px;
    padding: 12px 0;
    height: 240px;
  }

  .file-cover {
    width: 120px;
    height: 160px;
    border-radius: 8px;
  }