.user-learning-page-container{
    max-width: 1200px;
    margin: auto;
    padding-top: 20px;
    padding: 10px;
}

.user-learning-page-background{
    background-color: #ffffff;
}

.course {
    height: 100%;
    margin-top: 10px;
}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
}

.tab_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.line {
    height: 1px;
    background-color: #f5f5f5;
    margin: 20px 0;
}

.split {
    height: 8px;
    width: 48px;
    margin-top: 2px;
    background-color: #FFC001;
}

.split_line {
    width: 2px;
    height: 30px;
    background-color: '#f5f5f5';
    margin-top: 10px;
}

.serial_tag {
    background-color: '#FFC600';
    margin-left: 8px;
    height: 30px;
    border-radius: 15px;
}

.vip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(255,198,0,1) 0%, rgba(255,167,0,1) 45%, rgba(255,198,0,1) 100%);
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.org {
    display: flex;
    flex-direction: row;
}

.org_avatar {
    width: 50px;
    height: 50px;
    border-radius: 5px;
}

.tag {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
}

.buyWrap {
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 20px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buy {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
    width: 240px;
    height: 48px;
    border-radius: 24px;
    // background: linear-gradient(180deg, rgba(255,198,0,1) 0%, rgba(255,167,0,1) 45%, rgba(255,198,0,1) 100%);
    background: #EB0000;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2); 
    color: white;
    font-size: 14px;
    cursor: pointer;
}