.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// .user-learning-page-container{
//     max-width: 1200px;
//     margin: auto;
//     padding-top: 20px;
// }

// .user-learning-page-background{
//     background-color: #ffffff;
// }


.billmonth {
    // width: 368px;
    // margin: 24px auto;
    margin-top: 12px;
    border-bottom: 2px solid #FFA70F;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  
    h3 {
      font-size: 16px;
    //   margin-bottom: 20px;
      margin-right: 12px;
    }

    span {
        margin-right: 12px;
    }
  }

.header {
    button {
        margin: 12px;
    }
}

.resume-status-img {
  width: auto;
  height: 15px;
  margin-left: 12px;
  margin-right: 3px;
}