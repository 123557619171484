
  .user-layout-logo {
    width: 60px;
    height: 60px !important;
    margin-top: 20px;
    // margin: 10px 30px 10px 40px!important;
    // float: left;
    // position: relative;
  }
.user-layout-header{
  background-color: '#ffffff' ;
}

.user-layout-menu-city{
  // margin-top: 22px;
  background-color: '#ffffff' ;
}

.user-layout-search-input{
  margin-top: 17px;
}

.user-layout-upper-header{
  margin: auto;
  // min-width: 1000px;
  max-width: 1200px;
}

.user-content {
  margin: auto;
  // min-width: 1000px;
  max-width: 1200px;
}


// 弹窗样式
.cus-modal-wrapper {
  overflow: hidden;

  .ant-modal-content {
    width: 800px;
    height: 500px;

    background-image: url('../../images/jobcategory_bg.jpeg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    box-shadow: none;
    margin: 0 auto;

    & > div {
      border: 0;
      text-align: center;
    }

    .ant-modal-header {
      height: 6vw;
      background: none;

      .ant-modal-title {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .ant-modal-body {
      // height: 20vw;
      // padding: 2.5vw 3.1vw 0vw 3.3vw;
      display: flex;
      justify-content: center;
    }

    .ant-modal-footer {
      height: 3vw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.joblist {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  // width: 100%;
  flex: 1
}

.joblist::-webkit-scrollbar {
  // display: none;
  -webkit-appearance: none;
  width: 2px;
  height: 8px;
}

.joblist::-webkit-scrollbar-thumb {
  border-radius: 42px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}