
  .user-layout-logo {
    width: 60px;
    height: 60px !important;
    margin-top: 20px;
    // margin: 10px 30px 10px 40px!important;
    // float: left;
    // position: relative;
  }
.user-layout-header{
  background-color: '#ffffff' ;
}

.user-layout-menu-city{
  margin-top: 22px;
  background-color: '#ffffff' ;
}

.user-layout-search-input{
  margin-top: 17px;
}

.user-layout-upper-header{
  margin: auto;
  // min-width: 1000px;
  max-width: 1200px;
}

