.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-learning-page-container{
    max-width: 1200px;
    margin: auto;
    padding-top: 20px;
}

.user-learning-page-background{
    background-color: #ffffff;
}

.back {
    margin: 12px;
}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
}

.tab_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.split {
    height: 8px;
    width: 48px;
    margin-top: 2px;
    background-color: #FFC001;
}

.split_line {
    width: 2px;
    height: 30px;
    background-color: '#f5f5f5';
    margin-top: 10px;
}

.serial_tag {
    background-color: '#FFC600';
    margin-left: 8px;
    height: 30px;
    border-radius: 15px;
}

.buyDisable {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 48px;
    border-radius: 24px;
    background-color: grey;
    color: white;
    font-size: 12px;
}