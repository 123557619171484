.user-my-page-container{
    max-width: 1200px;
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;

}

.user-my-page-background{
    background-color: #e6e8eb;
}


.form-item {
    // margin-left: 12px;
    // width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
        // width: 120;
    }

    input {
        // width: 70%;
        // margin-left: 12px;
    }
}

.address-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;

    // span {
    //     width: 20%;
    // }
}

.address-city-item {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
    font-size: 14px;
    height: 40px;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .prefix {
        height: 100%;
        padding: 0 11px;
        border-right: 1px solid rgb(217, 217, 217);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        background-color: #fafafa;
        // text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .value {
        color: rgba(0, 0, 0, 0.65);
        padding: 0 11px;
        font-size: 16px;
    }

    .placeholder {
        color: #BFBFBF;
        padding: 0 11px;
        font-size: 16px;
    }
}