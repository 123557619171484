.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.user-learning-page-container{
    max-width: 1200px;
    margin: auto;
    padding-top: 20px;
}

.user-learning-page-background{
    background-color: #ffffff;
}


.main {
    width: 368px;
    margin: 24px auto;
  
    h3 {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }