.footer {
  padding-top: 2px;
  background-color: #ffffff !important;
  max-width: 1200px;
  margin: auto;
  padding-top: 20x;
  padding-bottom: 50px;
}

.link {
  cursor: pointer;
}

.float_tips_area {
  position:absolute;
  background-color: #ffffff;
  border-radius: 5px;
  display:none;
  right:20px;
  width:70%;
  z-index: 1000;
}
.mgf_tips_icons{
  display:inline-block;
}
.mgf_tips_icons:hover .float_tips_area{
   display:block;
   padding: 10px;
   border: 1px solid #dedede;
}


.float_tips_area2 {
  position:absolute;
  background-color: #ffffff;
  border-radius: 5px;
  display:none;
  right:20px;
  width:70%;
  z-index: 1000;
}
.mgf_tips_icons2{
  display:inline-block;
}
.mgf_tips_icons2:hover .float_tips_area2{
   display:block;
   padding: 10px;
   border: 1px solid #dedede;
}